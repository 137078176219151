.authBgImage {
  background-image: url('https://nftx.artjam.com/media/illustrations/bg.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.fill {
  min-height: 100%;
  height: 100%;
}
